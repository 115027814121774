import { createSlice } from '@reduxjs/toolkit'

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    token: null,
  },
  reducers: {
    loggedIn(state, { payload: { token } }) {
      state.token = token
    },
    loggedOut(state) {
      state.token = null
    },
  },
})

const updateUser = (token) => async (dispatch, getState, { api }) => {
  const userToken = token || getState().auth.token

  const response = await api.auth.user({ token: userToken })
  dispatch(
    authSlice.actions.loggedIn({ token: userToken, user: response.data }),
  )
}

const fetchUser = (token) => async (dispatch, getState) => {
  if (getState().auth.token === token) {
    return
  }

  await dispatch(updateUser(token))
}

const login = (data) => async (dispatch, getState, { api }) => {
  const response = await api.auth.login(data)
  const token = response.access_token

  await dispatch(fetchUser(token))

  return token
}

const logout = (onlyFront = false) => async (dispatch, getState, { api }) => {
  if (!getState().auth.token) return

  if (!onlyFront) {
    try {
      await api.auth.logout()
    } catch (e) {
      console.error(e)
    }
  }

  dispatch(authSlice.actions.loggedOut())
}

const register = (data) => async (dispatch, getState, { api }) => {
  await api.auth.register(data)
}

const recovery = (data) => async (dispatch, getState, { api }) => {
  const result = await api.auth.recovery(data)

  return result
}

export const { loggedIn, loggedOut } = authSlice.actions

export default {
  login,
  register,
  logout,
  recovery,
  fetchUser,
  updateUser,
  reducer: authSlice.reducer,
}
